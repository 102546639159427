import './style.css'
import * as THREE from 'three'
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls.js'
import { KeyboardKeyHold } from 'hold-event'
import Stats from 'stats.js'
import { Euler, Vector3 } from 'three';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import { createPointLight } from './modules/createPointLight.js'
import { importRoom } from './modules/importRoom'
import { createRenderer } from './modules/createRenderer'
import { generateFrame } from './modules/generateFrame'
import { createClock } from './modules/createClock'

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')
if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    window.location.replace("https://desktop.brianbaldner.com/info");
}
// Scene
const scene = new THREE.Scene()
const cssscene = new THREE.Scene()
const manager = new THREE.LoadingManager()
scene.background = new THREE.Color(0xffffff);
/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    cssRenderer.setSize(sizes.width, sizes.height)
    cssRenderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.01, 100)
camera.position.x = 0
camera.position.y = 0.5
camera.position.z = 0
scene.add(camera)
cssscene.add(camera)

// Controls
const controls = new PointerLockControls(camera, document.body);
const KEYCODE = {
    W: 87,
    A: 65,
    S: 83,
    D: 68,
    ARROW_LEFT: 37,
    ARROW_UP: 38,
    ARROW_RIGHT: 39,
    ARROW_DOWN: 40,
};
const speed = 0.1;
const wKey = new KeyboardKeyHold(KEYCODE.W, 5);
const aKey = new KeyboardKeyHold(KEYCODE.A, 5);
const sKey = new KeyboardKeyHold(KEYCODE.S, 5);
const dKey = new KeyboardKeyHold(KEYCODE.D, 5);


aKey.addEventListener('holding', function (event) {
    if (!onComputer) controls.moveRight(-0.01 * event.deltaTime * speed, 0, false)
});
dKey.addEventListener('holding', function (event) {
    if (!onComputer) controls.moveRight(0.01 * event.deltaTime * speed, 0, false)
});
wKey.addEventListener('holding', function (event) {
    if (!onComputer) controls.moveForward(0.01 * event.deltaTime * speed, false)
});
sKey.addEventListener('holding', function (event) {
    if (!onComputer) controls.moveForward(-0.01 * event.deltaTime * speed, false)
});

const raycaster = new THREE.Raycaster()

document.addEventListener('click', (event) => {
    if(event.target.id == "load" || event.target.className == "loader")
        console.log("nope");
    else if (!onComputer)
        controls.lock();
    else {
        onComputer = false;
        controls.connect();
        controls.lock();
    }
});

let onComputer = false;
let camlast = new Vector3()
let load = true;
document.addEventListener('keydown', (event) => {
    var name = event.key;
    var code = event.code;
    if (code == "KeyE" && hud.style.display == "block") {
        camlast = camera.position;
        console.log(camlast)
        onComputer = true;
        controls.disconnect();
        controls.unlock();
    }
});


importRoom(scene, controls);

const light2 = new THREE.HemisphereLight(0x525252, 0x525252, 3);
scene.add(light2);

/*const pointLight = createPointLight();
scene.add(pointLight);*/

const renderer = createRenderer(canvas);

const pmremGenerator = new THREE.PMREMGenerator(renderer);
    pmremGenerator.compileEquirectangularShader();

const cssRenderer = new CSS3DRenderer()
cssRenderer.setSize(sizes.width, sizes.height)
cssRenderer.domElement.style.position = 'absolute'
cssRenderer.domElement.style.top = 0
document.querySelector('#css3d').appendChild(cssRenderer.domElement)


const { webMesh, element } = generateFrame(1280, 720);
cssscene.add(webMesh);

createClock(scene);

//scene.add(clockobj);


/**
 * Animate
 */
const clock = new THREE.Clock()
let lastElapsedTime = 0

//const stats = new Stats()
//stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
//document.body.appendChild(stats.dom)
//const hud = document.getElementById('hud')

const xmax = 0.95;
const xmin = -1.7;
const zmax = 1.8;
const zmin = -0.95;
const tick = () => {
    //stats.begin()
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - lastElapsedTime
    lastElapsedTime = elapsedTime
    raycaster.setFromCamera({
        x: 0,
        y: 0
    }, camera);
    const intersects = raycaster.intersectObjects(scene.children, true);

    if (!intersects.findIndex(x => x.object.name.indexOf("laptop_") != -1) && !onComputer)
        hud.style.display = "block"
    else
        hud.style.display = "none"
    // Update controls
    // Render
    element.style.visibility = (onComputer ? "visible" : "hidden")
    if (onComputer) {
        camera.position.lerp(new Vector3(0, 0.41, -0.75), 0.2);
        camera.lookAt(webMesh.position)
    } else if (camera.position.y < 0.49) {
        camera.position.lerp(new Vector3(camera.position.x, 0.5, camera.position.z), 0.2)
    } else {
        camera.position.y = 0.5;
    }

    if (camera.position.x > xmax)
        camera.position.x = xmax;
    else if (camera.position.x < xmin)
        camera.position.x = xmin;
    if (camera.position.z > zmax)
        camera.position.z = zmax;
    else if (camera.position.z < zmin)
        camera.position.z = zmin;
    if(!(onComputer && camera.position.y <= 0.41 && camera.position.z <= -0.7499))
    {
    renderer.render(scene, camera)
    cssRenderer.render(cssscene, camera)
    }
    else
     console.log("no render")

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
    //stats.end()
}
addEventListener("resize", (event) => {renderer.render(scene, camera)
    cssRenderer.render(cssscene, camera)});
tick()